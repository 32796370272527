// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/SARAH_SCRIPT.OTF");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../fonts/CANDARALI.TTF");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../fonts/POPLARSTD.OTF");
exports = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "@font-face{font-family:sarahScript;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}@font-face{font-family:candaraLight;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}@font-face{font-family:poplarStd;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.highlight:hover{box-shadow:5px 5px 10px #d3d3d3 !important;border:1px solid #d3d3d3 !important;transition:.02s ease-in-out}", "",{"version":3,"sources":["/home/ubuntu/www/releases/20250213145322/app/javascript/stylesheets/application.scss"],"names":[],"mappings":"AAAA,WACE,uBAAA,CACA,2CAAA,CAEF,WACE,wBAAA,CACA,2CAAA,CAEF,WACE,qBAAA,CACA,2CAAA,CAGF,iBACE,0CAAA,CACA,mCAAA,CACA,2BAAA","file":"application.scss","sourcesContent":["@font-face {\n  font-family: sarahScript;\n  src: url('../fonts/SARAH_SCRIPT.OTF');\n}\n@font-face {\n  font-family: candaraLight;\n  src: url('../fonts/CANDARALI.TTF');\n}\n@font-face {\n  font-family: poplarStd;\n  src: url('../fonts/POPLARSTD.OTF');\n}\n\n.highlight:hover{\n  box-shadow: 5px 5px 10px lightgray !important;\n  border: 1px solid lightgray !important;\n  transition: .02s ease-in-out;\n}"]}]);
// Exports
module.exports = exports;
