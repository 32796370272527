<template>
  <div v-if="!hidePricing">
    <!-- Condensed Mode -->
    <div
      v-if="hasBreaks && (!expanded || view == 'mobile') && (offersSavings || offersShowSavings)"
      class="position-relative main"
      :class="view == 'mobile' ? 'mb-5' : 'mb-1'">
      <span
        v-if="view == 'mobile'"
        class="small text-muted fw-bold"
        style="font-size:12px;">
        Qty Breaks
      </span>
      <button
        v-else
        class="btn btn-sm btn-primary"
        @click="toggle"
        style="cursor: pointer; font-size:12px; padding: 0px 5px;">
        <i :class="show ? 'fa fa-caret-down' : 'fa fa-info-circle'"/>
        View Qty Breaks
      </button>
      <div
        v-if="show || view == 'mobile'"
        class="qty-card animate__animated animate__fadeIn animate__faster mt-1"
        :style="breakStyle">
        <table class="table table-sm table-dark text-center text-white">
          <thead v-show="!confirm">
            <th>Price</th>
            <th>Qty</th>
          </thead>
          <!-- Virtual Show Breaks -->
          <tbody v-if="virtualShow">
            <tr
              v-for="(b,i) in vShowBreaks"
              v-show="!confirm"
              :key="i">
              <td>{{numeral(b[`qty_break${i+1}_price`]).format('$0,0.00')}}</td>
              <td>
                <a
                  href="javascript: void(0)"
                  class="text-light"
                  :title="'Add ' + b[`qty_break${i+1}`] + b[`qty_break${(i+1) + '_uom'}`] + ' to cart'"
                  @click="addQty(b, true, i+1)">
                  {{numeral(b[`qty_break${i+1}`]).format('0,0')}}
                </a>
              </td>
            </tr>
            <tr
              v-if="confirm"
              class="animate__animated animate__bounceIn animate__faster">
              <td>
                <i class="fa fa-check-circle text-sucess fa-2x"/>
                <h4>Added to cart!</h4>
              </td>
            </tr>
          </tbody>
          <!-- Qty Breaks standard and promo-->
          <tbody v-else>
            <tr
              v-for="b in breaks"
              v-show="!confirm"
              :key="b.id"
              :class="b.promo ? 'bg-success' : ''">
              <template v-if="b.visible">
                <td class="position-relative">
                  {{numeral(b.price).format('$0,0.00')}}
                </td>
                <td>
                  <a
                    href="javascript: void(0)"
                    class="text-light"
                    :title="`Add ${b.quantity} ${displayUom(b.uom)} to cart`"
                    @click="addQty(b)">
                    {{numeral(b.quantity).format('0,0')}}
                  </a>
                </td>
              </template>
            </tr>
            <tr
              v-if="confirm"
              colspan="2"
              class="animate__animated animate__bounceIn animate__faster">
              <td class="text-center">
                <i class="fa fa-check-circle text-success fa-2x"/>
                <h5>Added to cart!</h5>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Expanded Mode -->
    <div v-if="hasBreaks && expanded && view != 'mobile' && (offersSavings || offersShowSavings)">
      <div class="text-center">
        <div
          class="fw-bold w-100 bg-primary text-white"
          style="font-size:12px">
          Qty Breaks
      </div>
        <table class="table table-sm text-center bg-primary-faded">
          <thead v-show="!confirm">
            <th>Price</th>
            <th>Qty</th>
          </thead>
          <!-- Virtual Show Breaks -->
          <tbody v-if="virtualShow">
            <tr
              v-for="(b,i) in vShowBreaks"
              v-show="!confirm"
              :key="i">
              <td>{{numeral(b[`qty_break${i+1}_price`]).format('$0,0.00')}}</td>
              <td>
                <a
                  href="javascript: void(0)"
                  :title="'Add ' + b[`qty_break${i+1}`] + b[`qty_break${(i+1) + '_uom'}`] + ' to cart'"
                  @click="addQty(b, true, i+1)">
                  {{numeral(b[`qty_break${i+1}`]).format('0,0')}}
                </a>
              </td>
            </tr>
            <tr
              v-if="confirm"
              class="animate__animated animate__bounceIn animate__faster">
              <td>
                <i class="fa fa-check-circle text-sucess fa-2x"/>
                <h4>Added to cart!</h4>
              </td>
            </tr>
          </tbody>
          <!-- Qty Breaks standard and promo-->
          <tbody v-else>
            <tr
              v-for="b in breaks"
              v-show="!confirm"
              :key="b.id">
              <template v-if="b.visible">
                <td class="position-relative">
                  {{numeral(b.price).format('$0,0.00')}}
                </td>
                <td>
                  <a
                    href="javascript: void(0)"
                    :title="`Add ${b.quantity} ${displayUom(b.uom)} to cart`"
                    @click="addQty(b)">
                    {{numeral(b.quantity).format('0,0')}}
                  </a>
                </td>
              </template>
            </tr>
            <tr
              v-if="confirm"
              colspan="2"
              class="animate__animated animate__bounceIn animate__faster">
              <td class="text-center">
                <i class="fa fa-check-circle text-success fa-2x"/>
                <h5>Added to cart!</h5>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import {showDetail, showQtyBreaks} from '../../../../shared/showDetail'
  import {promoProductBreaks} from '../../../../shared/promoHelpers'
  import { displayUom } from '../../../../shared/ProductFunctions'

  export default {
    props:{
      product: Object,
      view: {
        type: String,
        default: ''
      },
      virtualShow: {
        type: Boolean,
        default: false
      },
      activeUom:{
        type: Object
      }
    },
    data(){
      return{
        show: false,
        confirm: false
      }
    },
    mounted(){
      this.$emit('hasBreaks', this.hasBreaks)
    },
    methods:{
      toggle(){
        this.show = !this.show
        this.emitStatus()
      },
      open(){
        this.show = true
        this.emitStatus()
      },
      close(){
        this.show = false
        this.emitStatus()
      },
      emitStatus(){
        this.$emit('showQtyStatus', this.show)
      },
      addQty(qtyBreak, vShow = false, breakID = null){
        // Virtaul Show Qty Break Add
        if(vShow && breakID){
          this.product.cartData = {
            qty: parseInt(qtyBreak[`qty_break${breakID}`]),
            uom: qtyBreak[`qty_break${breakID}_uom`].uom,
            virtualShow: true
          }
        }else{
          // Standard Qty Break Add
          this.product.cartData = {
            qty: parseInt(qtyBreak.quantity),
            uom: qtyBreak.uom
          }
        }
        this.confirm = true
        setTimeout(function(){
          this.confirm = false
        }.bind(this), 3000)
        this.$store.dispatch('addToCart', this.product)
      },
      displayUom(uom){ return displayUom(uom) }
    },
    computed:{
      promoProductDetail(){return this.product.promo},
      promoProductBreaks(){return promoProductBreaks(this.product)},
      hasBreaks(){
        // Check for promo Item && promo breaks
        if(!this.virtualShow && this.promoProductDetail && this.promoProductBreaks){
          return true    
        }
        // Standrd qty breaks
        if(!this.virtualShow && this.product.eclipse_data?.price_breaks?.length > 1 && this.breaks){
          return true
        }
        // Virtual show qty breaks
        if(this.virtualShow && this.vShowBreaks){
          return true
        }
        return false
      },
      homeBranch(){return this.$store.getters.homeBranch},
      vShowBreaks(){
        if(this.virtualShow){
          const detail = showDetail(this.product)
          if(detail){
            return showQtyBreaks(detail)
          }
          return false
        }
        return false
      },
      promoBreaks(){
        const breaks = []
        if(this.promoProductDetail && this.promoProductBreaks){
          const ppd = this.promoProductDetail
          const breakLevels = [1,2,3]
          breakLevels.forEach(function(l){
            breaks.push({
              uom:  ppd[`qty_break${l}_uom`],
              price:  ppd[`qty_break${l}_price`],
              quantity:   ppd[`qty_break${l}`],
              promo: true
            })
          })
        }
        return breaks
      },
      breaks(){
        // Get breaks with base price break removed
        let breakList = _.reject(this.product.eclipse_data.price_breaks, {quantity: "1"})

        // Convert cs breaks to ea
        breakList.forEach(function(b){
          b.visible = true
          if(b.uom == 'cs' && this.product.uom2 == 'cs'){
            b.qty = b.qty * this.product.uom2_value
            b.uom = 'ea'
          }
        }.bind(this))

        // Combinded Qty breaks: promo & standard qty breaks when product is on promo
        if(this.promoProductBreaks){
          // Combine promoBreaks and standard breaks
          let combined = _.orderBy([..._.reject(this.promoBreaks, {uom: null}), ...breakList], 'price', 'desc')

          // Best price check when like quantities are present
          // When competing qty's present throw out the higher priced
          const checkLikeQty = (qb) => {
            const matches = _.filter(combined, {quantity: qb.quantity})
            if(matches.length > 1){
              const match = _.maxBy(matches,'price')
              if(match) match.visible = false
            }
          }

          // Check and throw out rates that dont offer savings
          const checkRate = (qb) => {
            if(qb.promo){
              const greaters = _.filter(breakList, b => b.quantity > qb.quantity)
              greaters.forEach((g) => {
                if(qb.price < g.price){
                  const match = _.find(combined, {price: g.price})
                  if(match) match.visible = false
                }
              })
            }
          }

          // Standardize datatypes & exclude standard breaks that dont offer savings
          combined.forEach((qb) => {
            qb.price = parseFloat(qb.price)
            qb.quantity = parseInt(qb.quantity)
            qb.visible = true
            checkLikeQty(qb)
            checkRate(qb)
          })

          return combined
        }
        return breakList
      },
      offersShowSavings(){
        let status = false
        if(this.virtualShow && this.vShowBreaks.length){
          const detail = showDetail(this.product)
          const bestPrice = parseFloat(_.min([this.product.eclipse_data?.unit_price, detail.show_price]))
          this.vShowBreaks.forEach(function(b, i){
            i += 1
            if(b[`qty_break${i}_price`] <= bestPrice){
              status = true
            }
          })
        }
        return status
      },
      offersSavings(){
        let status = false
        // Promo Qty Break Savings
        if(this.promoProductBreaks && this.promoProductDetail){
          const ppd = this.promoProductDetail
          this.promoBreaks.every(function(b){
            status = parseFloat(b.price) < ppd.promo_price
          })
          return status
        }
        // Standard Qty Break Savings
        if(this.product.eclipse_data?.price_breaks?.length > 1 && this.breaks.length > 0){
          this.breaks.every(function(b){
            const productPrice = this.activeUom ? this.activeUom.price : this.product.eclipse_data.unit_price
            status = b.price < productPrice
          }.bind(this))
          return status
        }
        return status
      },
      productView(){return this.$store.getters.productView},
      breakStyle(){
        if(this.productView == 'list' || this.view == 'mobile'){
          return {position: 'static', width: '100%'}
        }else{
          return {position: 'absolute', width: '12rem'}
        }
      },
      hidePricing(){return this.$store.getters.hidePricing},
      expanded(){
        const user = this.$store.getters.user
        return user.qty_breaks_expanded || false
      }
    }
  }
</script>

<style scoped>
  .qty-card{
    height: auto;
    background: var(--bs-primary);
    box-shadow: 1px 1px 5px gray;
    padding: 10px;
    border-radius: 5px;
    font-size:16px;
    font-weight: 500;
    z-index: 3;
  }
  th, td{
    font-size:12px;
  }

  .promo-row{
    color: var(--bs-success);
    font-weight: bolder;
  }
  .table-dark{
    --bs-table-bg: var(--bs-primary);
    --bs-table-color: var(--bs-light);
  }
  .bg-primary-faded{
    thead, tr > td{
      background: rgb(var(--bs-primary-rgb), 0.2)
    }
  }
</style>